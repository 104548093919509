export const trackWistiaInteractions = (obj) => {
  appendWistiaScript();
  window._wq = window._wq || [];
  window._wq.push({
    id: "_all",
    onReady: function (video) {
      try {
        video.bind("play", function () {
          insertNotification("Video Played");
        });
        video.bind("pause", function () {
          insertNotification("Video Paused");
        });
      } catch (error) {
        console.error("Error in Wistia video interaction tracking:", error);
      }
    },
  });
};

let notificationTimeout;
const insertNotification = (message) => {
  const rootElement = document.getElementById("renderHtml");
  if (rootElement) {
    let notificationContainer = rootElement.querySelector(".nc-wistia");
    if (notificationContainer) {
      const messageElement =
        notificationContainer.querySelector(".text-center");
      if (messageElement) {
        messageElement.textContent = message;
      }
      clearTimeout(notificationTimeout);
    } else {
      const notificationHTML = `
        <div role="dialog" aria-modal="true" class="nc-wistia fade modal show" tabindex="-1" style="display: block;opacity: 0;width: 500px;height: 140px;margin-left: 35vw;margin-top: 40vh;">
          <div class="modal-dialog modal-dialog-centered">
            <div class="modal-content" style="background-color: rgb(240, 173, 78); width: 300px; margin-left: 100px;">
              <div class="modal-body" style="border: none;">
                <div class="custom-padding" style="border: none;">
                  <div class="mb-0 card" style="background-color: rgb(240, 173, 78); border: none;">
                    <div class="card-body">
                      <div class="mt-0 row">
                        <p class="text-center text-white"><b>${message}</b></p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      `;
      rootElement.insertAdjacentHTML("beforeend", notificationHTML);
      notificationContainer = rootElement.querySelector(".nc-wistia");
    }
    notificationTimeout = setTimeout(() => {
      if (notificationContainer) {
        notificationContainer.remove();
      }
    }, 5000);
  } else {
    console.error('Root element with id="renderHtml" not found.');
  }
};

export const appendWistiaScript = () => {
  const scriptSrc = "https://fast.wistia.com/assets/external/E-v1.js";
  if (!document.querySelector(`script[src="${scriptSrc}"]`)) {
    const script = document.createElement("script");
    script.type = "text/javascript";
    script.src = scriptSrc;
    script.async = true;
    script.onerror = () => {
      console.error("Failed to load the Wistia script.");
    };
    document.head.appendChild(script);
    const style = document.createElement("style");
    style.innerHTML = `
      .mfplayerbody .nc-wistia {
        opacity: 1 !important;
      }
    `;
    document.head.appendChild(style);
  } else {
    console.log("Wistia script is already present.");
  }
};
